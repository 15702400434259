import { useState } from "react";
import Button from "../components/Button";
import ReactEcharts from "echarts-for-react";

const Perception = () => {
  const [dataset, setDataset] = useState("compact_suv");

  const options = {
    legend: {
      data: datasets[dataset].legend,
      orient: "horizontal",
      right: "center",
      top: "0",
      itemGap: 20,
      textStyle: {
        color: '#FFF',
        fontSize: 14,
        overflow: "break",
      },
      width: "100%",
    },
    radar: {
      shape: 'circle',
      indicator: datasets[dataset].indicator,
      splitArea: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: [
            'rgba(238, 197, 102, 0.1)',
            'rgba(238, 197, 102, 0.2)',
            'rgba(238, 197, 102, 0.4)',
            'rgba(238, 197, 102, 0.6)',
            'rgba(238, 197, 102, 0.8)',
            'rgba(238, 197, 102, 1)'
          ].reverse()
        }
      },
    },
    tooltip: {},
    series: [
      {
        type: "radar",
        areaStyle: {
          opacity: 0.1
        },
        symbol: 'none',
        data: datasets[dataset].values,
        lineStyle: {
          width: 1,
        }
      },
    ],
  };

  return (
    <div>
      <ReactEcharts style={{ height: "500px" }} option={options} />

      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        {Object.keys(datasets).map((key) => (
          <Button 
            key={key}
            selected={dataset === key}
            onClick={() => setDataset(key)}
          >
            {datasets[key].name}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default Perception;

const datasets = {
  compact_suv: {
    name: "Compact SUV",
    indicator: [
      { name: "Fuel Efficiency", max: 10, min: 4 },
      { name: "Cargo Space", max: 10, min: 4 },
      { name: "Safety Features", max: 10, min: 4 },
      { name: "Ride Comfort", max: 10, min: 4 },
      { name: "Technology Features", max: 10, min: 4 },
      { name: "Resale Value", max: 10, min: 4 },
    ],
    values: [
      {
        name: "Toyota RAV4",
        value: [6.8, 10, 8.4, 10.0, 7.8, 10],
      },
      {
        name: "Honda CR-V",
        value: [9, 10, 9.0, 7.5, 6.9, 9.5],
      },
      {
        name: "Mazda CX-5",
        value: [8.5, 5.2, 7.4, 8.6, 7.6, 8.1],
      },
      {
        name: "Hyundai Tucson",
        value: [6.5, 7.5, 10, 7.7, 6.6, 8.3],
      },
    ],
    legend: ["Toyota RAV4", "Honda CR-V", "Mazda CX-5", "Hyundai Tucson"],
  },
  night_creams: {
    name: "Night Creams",
    indicator: [
      { name: "Hydration", max: 10, min: 4 },
      { name: "Anti-aging", max: 10, min: 4 },
      { name: "Texture", max: 10, min: 4 },
      { name: "Ingredients", max: 10, min: 4 },
      { name: "Scent", max: 10, min: 4 },
      { name: "Price", max: 10, min: 4 },
    ],
    values: [
      {
        name: "Crème de La Mer",
        value: [8.5, 10, 9.0, 9.4, 8.0, 10],
      },
      {
        name: "Estée Lauder Advanced Night Repair",
        value: [7.8, 10, 5, 10, 8.7, 8.9],
      },
      {
        name: "Olay Regenerist Retinol 24",
        value: [6.6, 6.4, 5, 5.2, 5.9, 9.6],
      },
      {
        name: "Neutrogena Rapid Wrinkle Repair",
        value: [6.6, 10, 7.4, 5, 5.7, 5],
      },
    ],
    legend: [
      "Crème de La Mer",
      "Estée Lauder Advanced Night Repair",
      "Olay Regenerist Retinol 24",
      "Neutrogena Rapid Wrinkle Repair",
    ],
  },
  running_shoes: {
    name: "Running Shoes",
    indicator: [
      { name: "Fit", max: 10, min: 4 },
      { name: "Cushioning-aging", max: 10, min: 4 },
      { name: "Support", max: 10, min: 4 },
      { name: "Weight", max: 10, min: 4 },
      { name: "Breathability", max: 10, min: 4 },
      { name: "Durability", max: 10, min: 4 },
    ],
    values: [
      {
        name: "Nike Pegasus 39",
        value: [7.0, 10, 6.5, 7.8, 9.5, 8.0],
      },
      {
        name: "Brooks Ghost 15",
        value: [10, 9.0, 8.5, 9.0, 7.5, 8.5],
      },
      {
        name: "New Balance Fresh Foam 1080 v12",
        value: [7.5, 10, 6.0, 9.5, 9.0, 8.0],
      },
      {
        name: "Hoka One One Clifton 9",
        value: [5.5, 10, 6.0, 10, 7.0, 8.0],
      },
    ],
    legend: [
      "Nike Pegasus 39",
      "Brooks Ghost 15",
      "New Balance Fresh Foam 1080 v12",
      "Hoka One One Clifton 9",
    ],
  },
  network_providers: {
    name: "Network Providers",
    indicator: [
      { name: "Coverage", max: 10, min: 4 },
      { name: "Speed-aging", max: 10, min: 4 },
      { name: "Cost", max: 10, min: 4 },
      { name: "Data Allowance", max: 10, min: 4 },
      { name: "Customer Service", max: 10, min: 4 },
      { name: "Additional Features", max: 10, min: 4 },
    ],
    values: [
      {
        name: "Verizon",
        value: [10, 8.2, 7, 6.5, 10, 7],
      },
      {
        name: "T-Mobile",
        value: [8, 10, 7.5, 8, 7.6, 10],
      },
      {
        name: "AT&T",
        value: [6, 7.2, 5, 7.4, 5, 7],
      },
      {
        name: "US Cellular",
        value: [5, 6, 7.2, 5.5, 6.4, 5.4],
      },
    ],
    legend: ["Verizon", "T-Mobile", "AT&T", "US Cellular"],
  },
};