import ReactECharts from 'echarts-for-react';
import './Sentiment.css'; // Import CSS for media queries

const Sentiment = () => {
  const textColour = 'rgba(255, 255, 255, 1)';
  const textColourSeries = 'rgba(255, 255, 255, 0.5)';
  const sharedIndicators = [
    { name: 'Reputation', max: 5.0, min: 2.0 },
    { name: 'Technology', max: 5.0, min: 2.0 },
    { name: 'Variety', max: 5.0, min: 2.0 },
    { name: 'Reviews', max: 5.0, min: 2.0 },
    { name: 'Sustainability', max: 5.0, min: 2.0 },
    { name: 'Preference', max: 5.0, min: 2.0 }
  ];
  const radar = (center = ['50%', '50%']) => {
    return {
      center,
      radius: 120,
      indicator: sharedIndicators,
      axisName: {
        color: textColourSeries,
        fontSize: 12,
      },
      splitLine: {
        lineStyle: { color: 'rgba(255, 255, 255, 0.5)' }
      },
      splitArea: { show: false }
    }
  }
  // LLMs
  const seriesLLMs = (radarIndex = 0) => {
    return {
      name: 'LLMs',
      type: 'radar',
      radarIndex,
      data: [{
        name: 'GPT 4o',
        value: [4.10, 5.00, 3.96, 3.41, 3.60, 4.13]
      }, {
        name: 'Gemini 1.5 Pro',
        value: [4.90, 4.86, 4.04, 3.81, 3.31, 3.36]
      }, {
        name: 'Llama 3.1',
        value: [2.8, 4.70, 3.46, 3.20, 3.21, 4.4]
      }, {
        name: 'Claude 3.5',
        value: [4.81, 4.76, 4.61, 4.45, 3.33, 2.51]
      }],
      areaStyle: { opacity: 0.2 },
      lineStyle: {
        width: 1
      },
      symbol: 'none'
    }
  }
  // People
  const seriesPeople = (radarIndex = 0) => {
    return {
      name: 'People',
      type: 'radar',
      radarIndex,
      data: [{
        name: 'Instagram',
        value: [4.38, 3.96, 3.81, 3.60, 3.36, 2.61]
      }, {
        name: 'Tiktok',
        value: [4.13, 3.46, 3.31, 3.21, 2.8, 2.50]
      }, {
        name: 'Facebook',
        value: [3.96, 3.41, 3.33, 4.20, 3.8, 3.2]
      }, {
        name: 'Youtube',
        value: [4.1, 3.31, 2.61, 2.51, 3.2, 2.94]
      }],
      areaStyle: { opacity: 0.2 },
      lineStyle: {
        width: 1
      },
      symbol: 'none'
    }
  }
  const option = (radar, series) => {
    return {
      title: {
        text: ''
      },
      legend: {
        data: ['GPT 4o', 'Gemini 1.5 Pro', 'Llama 3.1', 'Claude 3.5', 'Instagram', 'Tiktok', 'Facebook', 'Youtube'],
        textStyle: { color: textColour },
        top: 20
      },
      tooltip: {},
      radar,
      series
    }
  };

  return (
    <div className="sentiment-container">
      <div className="header-container">
        <h3 style={{ color: textColour, fontFamily: 'arial' }}>
          What LLMs say
        </h3>

        <div className="is-mobile">
          <ReactECharts option={option([radar()], [seriesLLMs()])} style={{ height: 500, width: '100%' }} />
        </div>

        <h3 style={{ color: textColour, fontFamily: 'arial' }}>
          What people say
        </h3>

        <div className="is-mobile">
          <ReactECharts option={option([radar()], [seriesPeople()])} style={{ height: 500, width: '100%' }} />
        </div>
      </div>

      <div className="is-desktop">
        <ReactECharts option={option([radar(['25%', '50%']), radar(['75%', '50%'])], [seriesLLMs(0), seriesPeople(1)])} style={{ height: 500, width: '100%' }} />
      </div>
    </div>
  );
};

export default Sentiment;
