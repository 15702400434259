import ReactEcharts from "echarts-for-react";

const Awareness = () => {
  const textColour = 'rgba(255, 255, 255, 1)';
  const textColourSeries = 'rgba(255, 255, 255, 0.5)';
  const textColourDark = '#000';

  const chartOptions = {
    title: {
      text: 'Running Shoes - Brand Appearance %',
      left: 'center',
      textStyle: {
        color: textColour,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const value = params[0].value.toFixed(0);

        return `${params[0].name}: ${value}%`;
      },
      textStyle: {
        color: textColourDark,
      },
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLine: {
        lineStyle: {
          color: textColourSeries,
        },
      },
      axisLabel: {
        color: textColourSeries,
      },
      splitLine: {
        lineStyle: {
          color: textColourSeries,
        },
      },
      max: 100,
      min: 0,
    },
    yAxis: {
      type: 'category',
      data: [
        'On', 'Mizuno', 'New Balance', 'Saucony', 'Puma', 'Brooks', 'Asics', 'Adidas', 'Hoka', 'Nike'
      ],
      axisLine: {
        lineStyle: {
          color: textColourSeries,
        },
      },
      axisLabel: {
        color: textColourSeries,
      },
    },
    series: [
      {
        name: 'Appearance Percentage',
        type: 'bar',
        data: [
          22,
          26,
          33,
          57,
          63,
          86,
          92,
          99,
          99,
          100,
        ],
        label: {
          show: false,
          position: 'right',
          formatter: '{c}%',
          color: textColourSeries,
        },
        itemStyle: {
          color: function (params) {
            const colorList = [
              'rgba(84, 112, 198, 0.5)', // blue
              'rgba(145, 204, 117, 0.5)', // green
              'rgba(250, 200, 88, 0.5)', // yellow
              'rgba(238, 102, 102, 0.5)', // red
              'rgba(115, 192, 222, 0.5)', // cyan
              'rgba(59, 162, 114, 0.5)', // teal
              'rgba(252, 132, 82, 0.5)', // orange
              'rgba(154, 96, 180, 0.5)', // purple
              'rgba(234, 124, 204, 0.5)', // pink
              'rgba(255, 176, 46, 0.5)', // golden
            ];

            return colorList[params.dataIndex];
          },
        },
      },
    ],
    color: ['#5470C6'],
  };

  return (
    <div style={{ maxWidth: '768px', margin: '0 auto' }}>
      <ReactEcharts option={chartOptions} style={{ height: "500px", width: "100%" }} />
    </div>
  );
};

export default Awareness;
