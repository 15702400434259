import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Awareness from './pages/Awareness';
import Perception from './pages/Perception';
import Sentiment from './pages/Sentiment';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/awareness" element={<Awareness />} />,
      <Route path="/perception" element={<Perception />} />,
      <Route path="/sentiment" element={<Sentiment />} />,
      </>
  )
)

export default function App() {
  return (
    <RouterProvider router={router} />
  );
}
