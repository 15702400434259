import styled from "styled-components";

const Button = styled.button`
  background: ${({selected}) =>  selected ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255,255,255, 0.1)'};
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
`

export default Button;
